var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"row page-title align-items-center"},[_c('div',{staticClass:"col-sm-4 col-xl-6"},[_c('h4',{staticClass:"mb-1 mt-0"},[_vm._v("Dashboard android and iOS")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-xl-6"},[_c('div',{},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive mb-0"},[_c('h5',{staticClass:"text-center"},[_vm._v("Subscription iOS")]),_c('b-table-simple',{staticClass:"table table-hover table-nowrap mb-0"},[_c('b-thead',{staticClass:"thead-white"},[_c('b-tr',[_c('b-th',[_vm._v("Product name")]),_c('b-th',[_vm._v("Price")]),_c('b-th',{staticClass:"text-center"},[_vm._v("Active")]),_c('b-th',{staticClass:"text-center"},[_vm._v("Inactive")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Sub News & Content")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Subscription.iOS.subs_news_content.count *
                        _vm.orderAll.Subscription.iOS.subs_news_content.price
                      ).toFixed(2)))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.orderAll.Subscription.iOS.subs_news_content.Active))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.orderAll.Subscription.iOS.subs_news_content.Inactive))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Exclusive Training")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Subscription.iOS.sub_exclusive_training
                          .count *
                        _vm.orderAll.Subscription.iOS.sub_exclusive_training.price
                      ).toFixed(2)))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.orderAll.Subscription.iOS.sub_exclusive_training.Active))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.orderAll.Subscription.iOS.sub_exclusive_training
                        .Inactive))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Premium Members")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Subscription.iOS.sub_premium_members.count *
                        _vm.orderAll.Subscription.iOS.sub_premium_members.price
                      ).toFixed(2)))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.orderAll.Subscription.iOS.sub_premium_members.Active))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.orderAll.Subscription.iOS.sub_premium_members.Inactive))])],1)],1)],1)],1)])])])]),_c('div',{staticClass:"col-md-4 col-xl-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive mb-0"},[_c('h5',{staticClass:"text-center"},[_vm._v("Subscription Android")]),_c('b-table-simple',{staticClass:"table table-hover table-nowrap mb-0"},[_c('b-thead',{staticClass:"thead-white"},[_c('b-tr',[_c('b-th',[_vm._v("Product name")]),_c('b-th',[_vm._v("Price")]),_c('b-th',{staticClass:"text-center"},[_vm._v("Active")]),_c('b-th',{staticClass:"text-center"},[_vm._v("Inactive")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Sub News & Content")])]),_c('b-td',[_vm._v(_vm._s((
                      _vm.orderAll.Subscription.Android.subs_news_content.count *
                      _vm.orderAll.Subscription.Android.subs_news_content.price
                    ).toFixed(2)))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.orderAll.Subscription.Android.subs_news_content.Active))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.orderAll.Subscription.Android.subs_news_content.Inactive))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Exclusive Training")])]),_c('b-td',[_vm._v(_vm._s((
                      _vm.orderAll.Subscription.Android.sub_exclusive_training
                        .count *
                      _vm.orderAll.Subscription.Android.sub_exclusive_training
                        .price
                    ).toFixed(2)))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.orderAll.Subscription.Android.sub_exclusive_training
                      .Active))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.orderAll.Subscription.Android.sub_exclusive_training
                      .Inactive))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Premium Members")])]),_c('b-td',[_vm._v(_vm._s((
                      _vm.orderAll.Subscription.Android.sub_premium_members
                        .count *
                      _vm.orderAll.Subscription.Android.sub_premium_members.price
                    ).toFixed(2)))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.orderAll.Subscription.Android.sub_premium_members.Active))]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.orderAll.Subscription.Android.sub_premium_members.Inactive))])],1)],1)],1)],1)])])]),_c('div',{staticClass:"col-md-4 col-xl-6"},[_c('div',{},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive mb-0"},[_c('h5',{staticClass:"text-center"},[_vm._v("Transaction iOS")]),_c('b-table-simple',{staticClass:"table table-hover table-nowrap mb-0"},[_c('b-thead',{staticClass:"thead-white"},[_c('b-tr',[_c('b-th',[_vm._v("Product name")]),_c('b-th',[_vm._v("Price")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Muaythai Boran Recommend")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.iOS.muaythai_boran_recommend
                          .count *
                        _vm.orderAll.Transaction.iOS.muaythai_boran_recommend
                          .price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Petchtaksin's Combos And Tactics")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.iOS
                          .petchtaksin_s_combos_and_tactics.count *
                        _vm.orderAll.Transaction.iOS
                          .petchtaksin_s_combos_and_tactics.price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Mae-mai Daab Introduce")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.iOS.mae_mai_daab_introduce
                          .count *
                        _vm.orderAll.Transaction.iOS.mae_mai_daab_introduce.price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Knockout Techniques")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.iOS.knockout_techniques.count *
                        _vm.orderAll.Transaction.iOS.knockout_techniques.price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Muaythai Boran Introduce")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.iOS.muaythai_boran_introduce
                          .count *
                        _vm.orderAll.Transaction.iOS.muaythai_boran_introduce
                          .price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Learn Superlek's Fight Secrets")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.iOS
                          .learn_superlek_s_fight_secrets.count *
                        _vm.orderAll.Transaction.iOS
                          .learn_superlek_s_fight_secrets.price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Learn Jo's Fight Secrets")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.iOS.learn_jo_s_fight_secrets
                          .count *
                        _vm.orderAll.Transaction.iOS.learn_jo_s_fight_secrets
                          .price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Old School Tactics")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.iOS.old_school_tactics.count *
                        _vm.orderAll.Transaction.iOS.old_school_tactics.price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Chatchai's Knockout Tips")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.iOS.chatchai_s_knockout_tips
                          .count *
                        _vm.orderAll.Transaction.iOS.chatchai_s_knockout_tips
                          .price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Mae-mai Daab Recommend")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.iOS.mae_mai_daab_recommend
                          .count *
                        _vm.orderAll.Transaction.iOS.mae_mai_daab_recommend.price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Learn Silapathai's Fight Style")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.iOS
                          .learn_silapathai_s_fight_style.count *
                        _vm.orderAll.Transaction.iOS
                          .learn_silapathai_s_fight_style.price
                      ).toFixed(2)))])],1)],1)],1)],1)])])])]),_c('div',{staticClass:"col-md-4 col-xl-6"},[_c('div',{},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive mb-0"},[_c('h5',{staticClass:"text-center"},[_vm._v("Transaction Android")]),_c('b-table-simple',{staticClass:"table table-hover table-nowrap mb-0"},[_c('b-thead',{staticClass:"thead-white"},[_c('b-tr',[_c('b-th',[_vm._v("Product name")]),_c('b-th',[_vm._v("Price")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Muaythai Boran Recommend")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.Android.muaythai_boran_recommend
                          .count *
                        _vm.orderAll.Transaction.Android.muaythai_boran_recommend
                          .price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Petchtaksin's Combos And Tactics")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.Android
                          .petchtaksin_s_combos_and_tactics.count *
                        _vm.orderAll.Transaction.Android
                          .petchtaksin_s_combos_and_tactics.price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Mae-mai Daab Introduce")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.Android.mae_mai_daab_introduce
                          .count *
                        _vm.orderAll.Transaction.Android.mae_mai_daab_introduce
                          .price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Knockout Techniques")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.Android.knockout_techniques
                          .count *
                        _vm.orderAll.Transaction.Android.knockout_techniques.price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Muaythai Boran Introduce")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.Android.muaythai_boran_introduce
                          .count *
                        _vm.orderAll.Transaction.Android.muaythai_boran_introduce
                          .price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Learn Superlek's Fight Secrets")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.Android
                          .learn_superlek_s_fight_secrets.count *
                        _vm.orderAll.Transaction.Android
                          .learn_superlek_s_fight_secrets.price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Learn Jo's Fight Secrets")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.Android.learn_jo_s_fight_secrets
                          .count *
                        _vm.orderAll.Transaction.Android.learn_jo_s_fight_secrets
                          .price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Old School Tactics")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.Android.old_school_tactics
                          .count *
                        _vm.orderAll.Transaction.Android.old_school_tactics.price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Chatchai's Knockout Tips")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.Android.chatchai_s_knockout_tips
                          .count *
                        _vm.orderAll.Transaction.Android.chatchai_s_knockout_tips
                          .price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Mae-mai Daab Recommend")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.Android.mae_mai_daab_recommend
                          .count *
                        _vm.orderAll.Transaction.Android.mae_mai_daab_recommend
                          .price
                      ).toFixed(2)))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v("Learn Silapathai's Fight Style")])]),_c('b-td',[_vm._v(_vm._s((
                        _vm.orderAll.Transaction.Android
                          .learn_silapathai_s_fight_style.count *
                        _vm.orderAll.Transaction.Android
                          .learn_silapathai_s_fight_style.price
                      ).toFixed(2)))])],1)],1)],1)],1)])])])])]),_c('div',{staticClass:"row"},[_c('Memberstatchart',{staticClass:"col-md-4 col-xl-4",staticStyle:{"height":"fit-content"},attrs:{"main-title":_vm.statChart[1].mainTitle,"value":_vm.statChart[1].value,"sub-value":_vm.statChart[1].subValue,"chart-color":_vm.statChart[1].chartColor,"newMember":_vm.statChart2nd[0].value}}),_c('div',{staticClass:"col-md-4 col-xl-4"},[_c('div',{},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive mb-0"},[_c('b-table-simple',{staticClass:"table table-hover table-nowrap mb-0"},[_c('b-thead',{staticClass:"thead-white"},[_c('b-tr',[_c('b-th',[_vm._v("Member Subscription")]),_c('b-th',[_vm._v("Count")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v(_vm._s(_vm.memberStatus.free.name))])]),_c('b-td',[_vm._v(_vm._s(_vm.memberStatus.free.count))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v(_vm._s(_vm.memberStatus.active.name))])]),_c('b-td',[_vm._v(_vm._s(_vm.memberStatus.active.count))])],1),_c('b-tr',[_c('b-td',[_c('span',{staticClass:"badge"},[_vm._v(_vm._s(_vm.memberStatus.inactive.name))])]),_c('b-td',[_vm._v(_vm._s(_vm.memberStatus.inactive.count))])],1)],1)],1)],1)])])])]),_c('div',{staticClass:"col-md-4 col-xl-4"},[_c('Overview',{attrs:{"items":_vm.overviewData,"title":"Best selling"}})],1)],1),_c('div',{staticClass:"row"})])
}
var staticRenderFns = []

export { render, staticRenderFns }